import { Link } from "gatsby"
import React from "react"
import Logo from "../images/sc-logo.svg"
import Socials from "../components/Socials"

const Footer = () => {

  return(
    <footer className="z-50 w-full px-5 bg-black relative">
      <div className="container mx-auto text-white py-10">
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
          <div className="flex flex-col justify-center items-center md:items-start">
            <Link to="/" className="text-2xl flex flex-row items-center"><img src={Logo} width="25px" className="mr-2" /> Steve Cook</Link>
            <div className="mt-6">
              <Socials />
            </div>
          </div>
          <div className="mt-10 md:mt-auto" style={{transform: "rotate(-5deg)"}}>
            <h3 className="text-3xl text-white">Last Set,</h3>
            <h3 className="text-3xl text-white">Best Set</h3>
          </div>
        </div>
      </div>
      <div className="container mx-auto py-5">
        <div className="flex justify-center items-center text-white">
          © {new Date().getFullYear()}
          {` `}
          <a href="https://www.stevecookhealth.com" className="ml-2"> Steve Cook</a>
        </div>
      </div>
    </footer>
  )
}


export default Footer
