import React from "react";

import Twitter from "../images/twitter.svg"
import Facebook from "../images/facebook.svg"
import Instagram from "../images/instagram.svg"
import YouTube from "../images/youtube.svg"


const Socials = () => {

  return(
    <div className="flex items-center justify-center">
      <a href="https://www.youtube.com/channel/UC3kbAe8BguiZFnsVYY1oW0w/" target="_blank" rel="noopener" className="pr-2"><img src={YouTube} width="100%" className="w-12" /></a>
      <a href="https://www.instagram.com/stevecook/" target="_blank" rel="noopener" className="px-2"><img src={Instagram} width="100%" className="w-12" /></a>
      <a href="https://www.facebook.com/SteveCookFitness" target="_blank" rel="noopener" className="px-2"><img src={Facebook} width="100%" className="w-12" /></a>
      <a href="https://twitter.com/stevecook" target="_blank" rel="noopener" className="pl-2"><img src={Twitter} width="100%" className="w-12" /></a>
    </div>
  )
}

export default Socials