import React from "react";


const CampaignForm = ({campaign, cta}) => {

  return(
    <form id="campaignForm" className="w-full max-w-lg" data-drip-embedded-form={campaign} action={`https://www.getdrip.com/forms/${campaign}/submissions`} method="post">
      <span data-drip-attribute="headline" className="hidden"></span>
      <span data-drip-attribute="description" className="hidden"></span>
      <div className="mt-10 w-full bg-white border-b border-black rounded-sm flex flex-col lg:flex-row justify-end p-2 relative shadow">
        <input className="flex-grow p-4 md:p-6" id="grid-email" type="email" name="fields[email]" placeholder="Email" required/>
        <button type="submit" className="absolute px-4 py-2 rounded-sm text-white text-base md:text-2xl text-center shadow button button-cta" style={{background: "#9d895a"}}>{cta}</button>
      </div>
      <p className="mt-4 text-center md:text-left text-white text-sm">Join <strong>3.7M</strong> other people striving to be their best.</p>
    </form>
  )
}

export default CampaignForm