import { Link } from "gatsby"
import React, {useEffect, useState} from "react"
import Logo from "../images/sc-logo.svg"

const Header = () => {

  const [sticky, setSticky] = useState("")
  const [open, setOpen] = useState(false)
  const [slide, setSlide] = useState("slide-left")

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0){
        setSticky("fixed nav-fixed");
      } else {
        setSticky("nav")
      }
    })
  })

  const openDrawer = () => {
    setOpen(!open)
    open ? setSlide("slide-left") : setSlide("slide-right")
  }



  return(
    <>
    {/* <div className="black-paper h-10 w-full fixed flex justify-center items-center z-50">
      <a href="https://my.community.com/stevecook" className="text-white text-center text-xl">Text me anytime: +1 (208) 487-8241</a>
    </div> */}
    <header className={`fixed z-50 w-full p-5 ${sticky}`}>
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <div className="flex items-center justify-center">
            <button onClick={openDrawer} className="block lg:hidden flex items-center px-3 md:px-0 md:pr-3 py-2">
              <svg className="fill-current h-3 w-3 text-black" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
            </button>
            <Link to="/" className="text-2xl flex flex-row items-center"><img src={Logo} width="25px" className="mr-2" /> Steve Cook</Link>
          </div>
          <div className={`flex md:flex-row flex-col items-center justify-between nav-slide ${slide}`}>
            <a href="https://stevecook.merchlabs.com" className="text-black md:mr-4 text-xl" target="_blank" rel="noopener">Shop</a>
            <Link to="/my-story" className="text-black md:mr-4 text-xl">Story</Link>
            <div className="dropdown">
              <span className="text-black md:mr-4 text-xl link">Training</span>
              <div class="dropdown-content">
                <Link to="/mov3" className="text-black mt-4 text-xl">Lifestyle Training</Link>
                <Link to="/fitness-culture" className="text-black mt-4 text-xl">Advanced Training</Link> 
              </div>
            </div>
            <Link to="/contact" className="text-black md:mr-4 text-xl">Contact</Link>
            <Link to="/vip" className="px-4 py-1 border-2 border-black rounded-sm shadow text-black text-xl hidden md:block">Be A VIP</Link>
          </div>
          <div className="flex items-center justify-between md:hidden block">
            <Link to="/vip" className="px-4 py-1 border-2 border-black rounded-sm shadow text-xl">Be A VIP</Link>
          </div>
        </div>
      </div>
    </header>
    </>
  )
}


export default Header
